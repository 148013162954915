import {createReducer, on} from '@ngrx/store';
import {ActivityDiff} from '../../../../models/cdx-activity';
import {CdxAttachment} from '../../../../models/cdx-attachment';
import {CdxComment} from '../../../../models/cdx-comment';
import {EntityDetails} from '../../../../models/entity-details';
import {Utils} from '../../../../utils/utils';
import {
  addReplyFailedAction,
  addReplyStartAction, deleteAttachmentFailedAction, deleteAttachmentStartAction, deleteAttachmentSucceededAction,
  deleteCommentFailedAction,
  deleteCommentStartAction,
  deleteReplyFailedAction,
  deleteReplyStartAction,
  loadAttachmentFailedAction,
  loadAttachmentStartAction,
  loadAttachmentSucceededAction,
  loadCommentFailedAction,
  loadCommentStartAction,
  loadCommentSucceededAction,
  loadCurrentActivityDiffFailedAction,
  loadCurrentActivityDiffStartAction,
  loadCurrentActivityDiffSucceededAction,
  loadDetailsFailedAction,
  loadDetailsStartAction,
  loadDetailsSucceededAction,
  loadEntityDetailsFailedAction,
  loadEntityDetailsStartAction,
  loadEntityDetailsSucceededAction,
  loadHistoryFailedAction,
  loadHistoryStartAction,
  loadHistorySucceededAction, removeAllSucceededAction, updateAttachmentFailedAction, updateAttachmentStartAction, updateAttachmentSucceededAction,
  updateCommentsFailedAction,
  updateReplyFailedAction,
  updateReplyStartAction, updateTokenAction, uploadAttachmentFailedAction,
  uploadAttachmentStartAction,
  uploadAttachmentSucceededAction,
  addCommentFailedAction, addCommentStartAction, updateCommentStartAction
} from '../../action/entity/entity-details.action';

export class EntityDetailsState {
  datas: EntityDetails;
  loading: boolean;
  error: any;
}

const CURRENT_ENTITY_INITIAL_STATE: EntityDetailsState = {
  datas: null,
  loading: false,
  error: null,
};

export const entityDetailsReducer = createReducer(CURRENT_ENTITY_INITIAL_STATE,

  on(loadDetailsStartAction,
    loadEntityDetailsStartAction,
    loadCurrentActivityDiffStartAction,
    state => ({...state, loading: true, error: null})),
  on(loadEntityDetailsSucceededAction, (state, action) => ({
    ...state,
    datas: {
      ...state.datas,
      details: action.payload.details,
      loadPdf: false
    },
    loading: false,
    error: null,
  })),
  on(loadDetailsSucceededAction, (state, action) => {
    const copy = {
      ...state.datas,
      details: action.payload.details,
      comments: Utils.setCommentOrder(action.payload.comments),
      attachments: action.payload.attachments,
      activities: action.payload.activities,
      docEsPage: action.payload.docEsPage,
      token: action.payload.details.token,
      currentActivityDiff: new ActivityDiff()
    };
    return {
      ...state,
      datas: copy,
      loading: false,
      error: null,
    };
  }),
  on(loadCurrentActivityDiffFailedAction, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(loadDetailsFailedAction,
    loadEntityDetailsFailedAction,
    (state, action) => ({
      ...state,
      loading: false,
      error: action.error,
    })
  ),
  on(loadCurrentActivityDiffSucceededAction, (state, action) => ({
    ...state,
    datas: {
      ...state.datas,
      currentActivityDiff: action.payload.currentActivityDiff,
      loadPdf: false
    },
    loading: false,
    error: null,
  })),
  on(addCommentStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(addCommentFailedAction, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(updateCommentStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(updateCommentsFailedAction, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(deleteCommentStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(deleteCommentFailedAction, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(addReplyStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(addReplyFailedAction, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(updateReplyStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(updateReplyFailedAction, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(deleteReplyStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(deleteReplyFailedAction, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(loadAttachmentStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadAttachmentSucceededAction, (state, action) => {
    const attachmentIdToSearchFor = action.payload.attachments.length > 1 ? action.payload.attachments[1].cdx_id : action.payload.attachments[0].cdx_id;
    const upsertAttachement: CdxAttachment = action.payload.attachments[0];
    let found = false;
    let attachments: CdxAttachment[] = state.datas.attachments.map(att => {
      if (att.cdx_id === attachmentIdToSearchFor) {
        found = true;
        return upsertAttachement;
      }
      return att;
    });
    if (!found) {
      attachments = attachments.concat(upsertAttachement);
    }
    return {
      ...state,
      datas: {
        ...state.datas,
        attachments: attachments,
      },
      loading: false,
      error: null,
    };
  }),
  on(loadAttachmentFailedAction, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(loadHistoryStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadHistorySucceededAction, (state, action) => ({
    ...state,
    datas: {
      ...state.datas,
      activities: action.payload.activities,
    },
    loading: false,
    error: null,
  })),
  on(loadHistoryFailedAction, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(loadCommentStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadCommentSucceededAction, (state, action) => {
    const upsertComment: CdxComment = action.payload.comments[0];
    let foundCom = false;
    let commentss: CdxComment[] = state.datas.comments.map(comm => {
      if (comm.cdx_id === upsertComment.cdx_id) {
        foundCom = true;
        return upsertComment;
      }
      return comm;
    });
    if (!foundCom) {
      commentss = commentss.concat(upsertComment);
    }
    return {
      ...state,
      datas: {
        ...state.datas,
        comments: Utils.setCommentOrder(commentss),
      },
      loading: false,
      error: null,
    };
  }),
  on(loadCommentFailedAction, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(uploadAttachmentStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(uploadAttachmentSucceededAction, (state, action) => ({
    ...state,
    datas: {
      ...state.datas,
      attachments: state.datas.attachments ? state.datas.attachments.concat(action.payload.attachments) : action.payload.attachments,
    },
    loading: false,
    error: null,
  })),
  on(uploadAttachmentFailedAction, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(deleteAttachmentStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(deleteAttachmentSucceededAction, (state, action) => ({
    ...state,
    datas: {
      ...state.datas,
      attachments: state.datas.attachments.filter(att => att.cdx_id !== action.payload.attachments[0].cdx_id)
    },
    loading: false,
    error: null,
  })),
  on(deleteAttachmentFailedAction, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(updateAttachmentStartAction, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(updateAttachmentSucceededAction, (state, action) => {
    const toReplace = state.datas.attachments.map(att => att.cdx_id).indexOf(action.payload.attachments[0].cdx_id);
    const updated = action.payload.attachments[0];
    updated.cdx_thumbnail = state.datas.attachments[toReplace].cdx_thumbnail;
    state.datas.attachments.splice(toReplace, 1, updated);
    return {
      ...state,
      datas: {
        ...state.datas,
        attachments: state.datas.attachments
      },
      loading: false,
      error: null,
    };
  }),
  on(updateAttachmentFailedAction, (state, action) => ({
    ...state,
    loading: false,
    error: action.error,
  })),
  on(removeAllSucceededAction, (state)=> ({
    ...state,
    datas: null,
    loading: false,
    error: null,
  })),
  on(updateTokenAction, (state, action) => {
    return {
      ...state,
      datas: {
        ...state.datas,
        details: {
          ...state.datas.details,
          token: action.payload.token
        }
      },
      loading: false,
      error: null,
    };
  })
);


