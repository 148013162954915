import { HttpHeaders, HttpResponse } from '@angular/common/http';

import { CdxComment } from '../models/cdx-comment';
import { CdxFile } from '../models/cdx-file';
import { Entity } from '../models/Entity';
import { InternalRoutes } from '../models/internal-routes';
import { CdxDocument } from '../models/cdx-document';
import { CdxTask } from '../models/cdx-task';
import { XslTransfoResult } from '../models/XslTransfoResult';
import { BlobFile } from '../models/blob-file';

/*
const I18N_FIELD_MARKER = '.i18n.';
const VALUE_FIELD_SUFFIX = '.value';
const FILE_NAME = 'cdx_file.cdx_name';
const FILE_SIZE = 'cdx_file.cdx_size';
const CDX_LINKS = 'cdx_links.';
*/

export enum KeyValue {
  KEY = 'key',
  VALUE = 'value'
}

export enum ErrorTypes {
  KEY = 'key',
  VALUE = 'value',
  DATA = 'data',
  OPTION = 'option',
  DOC_TYPE = 'docType',
  DRAFT_ID = 'draftId'
}

export class ActionEventEmitter {
  public emitEvent: boolean;
  constructor(emitEvent: boolean) {
    this.emitEvent = emitEvent;
  }
}

export class Utils {
  private static applicationConfigResponse = null;

  static dateToString(date: Date, currentLang: string, withLocalTime = false) {
     let result = date === null ? '' : date.toLocaleDateString(currentLang);
     if (withLocalTime) {
       result += ' ' + date.toLocaleTimeString(currentLang);
     }
    return result;
  }

  static stringToDate(str: string): Date {
    let _receiveDate: Date = null;

    try {
      _receiveDate = new Date(str);
    } catch (ex) {
      return;
    }
    return _receiveDate;
  }

  /*
  static getInitials(user: User) {
    const initials = (user.firstName == null ? '' : user.firstName.toUpperCase().substring(0, 1)) +
      (user.lastName == null ? '' : user.lastName.toUpperCase().substring(0, 1));
    return initials;
  }

  static getFullName(user: User) {
    const fullName = (user.firstName == null ? '' : user.firstName) + ' ' +
      (user.lastName == null ? '' : user.lastName);
    return fullName;
  }
  */

  static httpResponseToCdxFile(response: HttpResponse<any>): CdxFile {
    const type = response.headers.get('Content-Type');
    let fileName = response.headers.get('Content-Disposition');
    let file: CdxFile;
    if (fileName && fileName != null) {
      file = new CdxFile();
      fileName = fileName.split(';')[1].trim().split('=')[1];
      file.filename = fileName.replace(/"/g, '');
      file.url = window.URL.createObjectURL(new Blob([response.body], { type: type }));
    }
    return file;
  }

  static httpResponseToBlobFile(response: HttpResponse<ArrayBuffer>): BlobFile | null {
    const type = response.headers.get('Content-Type');
    let fileName = response.headers.get('Content-Disposition');
    let file: BlobFile = null;
    if (fileName) {
      file = new BlobFile();
      fileName = fileName.split(';')[1].trim().split('=')[1];
      file.filename = fileName.replace(/"/g, '');
      file.blob = new Blob([response.body], { type: type });
      file.fileSize = response.body.byteLength;
    }
    return file;
  }

  static convertCdxFileToLink(file: CdxFile) {
    const link = document.createElement('a');
    link.href = file.url;
    link.download = file.filename;
    link.classList.add('fileDownload');
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  static setCommentOrder(comments: CdxComment[]): CdxComment[] {
    let result = null;
    if (comments) {
      result = Utils.orderBy(comments.map(comment => Object.assign(new CdxComment(), comment)), ['-cdx_creation_date']);
      for (let index = 0; index < result.length; index++) {
        result[index].order = result.length - index;
      }
    }
    return result;
  }

  static getMapKeysValues(paramMap: Map<string, Entity[]> | Map<string, boolean>, keyValue: KeyValue): string[] | Entity[] | boolean[] | boolean {
    const keys: string[] = Array.from(paramMap.keys());
    if (keys[0] === null) {
      return [];
    }
    switch (keyValue) {
      case KeyValue.KEY:
      {
        return keys;
      }
      case KeyValue.VALUE:
      {
        const response: any[] = [];
        if (keys.length > 1) {
          keys.forEach((key: string) => {
            response.push(paramMap.get(key));
          });
          return response;
        } else {
          if (!!paramMap.get(keys[0])) {
            return paramMap.get(keys[0]);
          } else {
            return null;
          }
        }
      }
    }
  }

  static createErrorEvent(errorType: ErrorTypes): ErrorEvent {
    let error: ErrorEvent;
    switch (errorType) {
      case ErrorTypes.KEY:
        error = new ErrorEvent('error', {
          error: new Error('null or undefined'),
          message: 'key cannot be null or undefined'
        });
      break;
      case ErrorTypes.VALUE:
        error = new ErrorEvent('error', {
          error: new Error('null or undefined'),
          message: 'value cannot be null or undefined'
        });
      break;
      case ErrorTypes.DATA:
        error = new ErrorEvent('error', {
          error: new Error('null or undefined'),
          message: 'data cannot be null or undefined'
        });
      break;
      case ErrorTypes.OPTION:
        error = new ErrorEvent('error', {
          error: new Error('null or undefined'),
          message: 'option cannot be null or undefined'
        });
      break;
      case ErrorTypes.DOC_TYPE:
        error = new ErrorEvent('error', {
          error: new Error('null or undefined'),
          message: 'docType cannot be null or undefined'
        });
      break;
      case ErrorTypes.DRAFT_ID:
        error = new ErrorEvent('error', {
          error: new Error('null or undefined'),
          message: 'draftId cannot be null or undefined'
        });
      break;
    }
    return error;
  }

  static filter(items: any[], field, value): any[] {
    if (!items) {
      return [];
    }
    return items.filter(it => it[field] === value);
  }

  static filterByFields(items: any[], fields: any[], value): any[] {
    if (!items || items == null) {
      return [];
    }
    if (value == null || value.length === 0) {
      return items;
    }
    const val = value.toLowerCase();
    return items.filter(item => {
      return fields.some(field => {
        const fieldArr = field.split('.');
        let it;
        if (fieldArr.length > 1) {
          it = item;
          fieldArr.forEach((fieldFromArray: string) => {
            it = it[fieldFromArray];
          });
        } else {
          if (!!item[fieldArr[0]]) {
            if (item[fieldArr[0]] instanceof Array) {
              const newSearchFields = fields.filter(oldField => !oldField.includes(fieldArr[0]));
              it = this.filterByFields(item[fieldArr[0]], newSearchFields, value);
            } else {
              it = item[fieldArr[0]];
            }
          } else {
            it = [];
          }
        }
        if (typeof it === 'string') {
          it = it.toLowerCase();
          return it.includes(val);
        } else {
          return it.length > 0 ;
        }
      });
    });
  }

  static groupBy(items, field) {
    const groupedObj = items.reduce((prev, cur) => {
      if (!prev[cur[field]]) {
        prev[cur[field]] = [cur];
      } else {
        prev[cur[field]].push(cur);
      }
      return prev;
    }, {

      });
    return Object.keys(groupedObj).map(key => ({ key, items: groupedObj[key] }));
  }

  static orderBy(input: any, [config = '+']): any {
    if (!input) {
      return input;
    }

    if (!Array.isArray(input)) {
      return input;
    }

    if (!Array.isArray(config) || (Array.isArray(config) && config.length === 1)) {
      const propertyToCheck: string = !Array.isArray(config) ? config : config[0];
      const desc = propertyToCheck.substr(0, 1) === '-';

      // Basic array
      if (!propertyToCheck || propertyToCheck === '-' || propertyToCheck === '+') {
        return !desc ? input.sort() : input.sort().reverse();
      } else {
        const property: string = propertyToCheck.substr(0, 1) === '+' || propertyToCheck.substr(0, 1) === '-'
          ? propertyToCheck.substr(1)
          : propertyToCheck;

        return input.sort((a: any, b: any) => {
          return !desc
            ? Utils._orderByComparator(a[property], b[property])
            : -Utils._orderByComparator(a[property], b[property]);
        });
      }
    } else {
      // Loop over property of the array in order and sort
      return input.sort((a: any, b: any) => {
        for (let i = 0; i < config.length; i++) {
          const desc = config[i].substr(0, 1) === '-';
          const property = config[i].substr(0, 1) === '+' || config[i].substr(0, 1) === '-'
            ? config[i].substr(1)
            : config[i];

          const comparison = !desc
            ? Utils._orderByComparator(a[property], b[property])
            : -Utils._orderByComparator(a[property], b[property]);

          // Don't return 0 yet in case of needing to sort by next property
          if (comparison !== 0) {
            return comparison;
          }
        }

        return 0; // equal each other
      });
    }
  }

  static _orderByComparator(a: any, b: any): number {
    if (a == null && b != null) { return -1; }
    if (a != null && b == null) { return 1; }
    if (a == null && b == null) { return 0; }

    // spe case....
    if (isNaN(a) && isNaN(b)) {
      return b.toString().localeCompare(a.toString());
    }

    if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
      // Isn't a number so lowercase the string to properly compare
      if (a.toLowerCase() < b.toLowerCase()) { return -1; }
      if (a.toLowerCase() > b.toLowerCase()) { return 1; }
    } else {
      // Parse strings as numbers to compare properly
      if (parseFloat(a) < parseFloat(b)) { return -1; }
      if (parseFloat(a) > parseFloat(b)) { return 1; }
    }

    return 0; // equal each other
  }

  static search(items, searchString) {
    if (items == null) {
      return items;
    }

    if (searchString == null || searchString.length === 0) {
      return items;
    }

    return items.filter(Utils.compareWithAllFields, searchString);
  }

  static compareWithAllFields(value,/* index*/) {
    const fields = Object.keys(value);
    for (let i = 0; i < fields.length; i++) {
      if (value[fields[i]] != null) {
        // if (true) {  // isObject(value[fields[i]])
          const childFields = Object.keys(value[fields[i]]);

          if (childFields.length > 0) {
            for (let j = 0; j < childFields.length; j++) {
              if ((value[fields[i]][childFields[j]] + '').toLowerCase().indexOf(this.toString().toLowerCase()) !== -1) {
                return true;
              }
            }
          }
        // }
        if ((value[fields[i]] + '').toLowerCase().indexOf(this.toString().toLowerCase()) !== -1) {
          return true;
        }
      }
    }
    return false;
  }

  static createFile(response: HttpResponse<any>) {
    const dataType: string = response.headers.get('Content-Type');
    const uint8View: Uint8Array = new Uint8Array(response.body );
    const blob: Blob = new Blob([uint8View.buffer as BlobPart], {type: dataType});
    const name: string = 'thumb_' + response.url.substring(response.url.lastIndexOf('/') + 1) + '.' + dataType.substring(dataType.lastIndexOf('/') + 1);
    return Utils.newFile(blob, name, dataType);
  }

  static newFile(blob: Blob, name: string, dataType: string): File {
    return new File([blob], name, {type: dataType});
  }

  private static isVisible(element: any): boolean {
    return element
      && element.style.display !== 'none'
      && element.offsetWidth
      && element.offsetHeight;
  }

  public static isComponentVisible(comp: any): boolean {
    if (comp != null) {
      if (comp.nativeElement != null) {
        if (Utils.isVisible(comp.nativeElement)) {
          return true;
        }
      }
    }
    return false;
  }

  public static uuid(): string {
    let result;
    result = '';
    for (let j = 0; j < 32; j++) {
      if ( j === 8 || j === 12 || j === 16 || j === 20) {
        result = result + '-';
      }
      const i = Math.floor(Math.random() * 16).toString(16).toUpperCase();
      result = result + i;
    }
    return result;
  }

  public static notNullAndNotUndefined(value: any): boolean {
    return value !== null && value !== undefined;
  }

  public static returnNexiaObjectIdByType(type: InternalRoutes, nexiaObject: CdxDocument | Entity | CdxTask): string {
    if (!type || !nexiaObject) {
      return '';
    }
    switch (type) {
      case InternalRoutes.DOCUMENTS:
      case InternalRoutes.ENTITIES:
        return nexiaObject.cdx_id;
      case InternalRoutes.TASKS:
        return (nexiaObject as CdxTask).cdx_wkf.cdx_process.task_instance_id;
    }
  }

  public static doXslTransfo(sXml: string, sXsl: string): XslTransfoResult {
    let result = null;
    let errorMessageLocalKey = null;
    let errorMessageDetail = '';

    if ( Utils.notNullAndNotUndefined(sXml) && sXml.trim().length === 0) {
        errorMessageLocalKey = 'XML.error.emptyXml';
    } else {
      try {
        const parser = new DOMParser();
        const xmlDoc: XMLDocument = parser.parseFromString(sXml, 'text/xml');
        const parseErrorXml = Utils.getParseError(xmlDoc);
        if ( parseErrorXml === null ) {
          try {
            const xslDoc: XMLDocument =  parser.parseFromString(sXsl, 'text/xml');
            const parseErrorXsl = Utils.getParseError(xslDoc);
            if ( parseErrorXsl === null ) {
              try {
                const xsltProcessor = new XSLTProcessor();
                xsltProcessor.importStylesheet( xslDoc );
                const transforesult: XMLDocument = xsltProcessor.transformToDocument( xmlDoc );
                if ( !!transforesult ) {
                  result = transforesult.documentElement.outerHTML;
                } else {
                  errorMessageLocalKey = 'XML.error.transfoXslFailedNoResult'; // 'Error : Xsl transfo Failed : no result'
                }
              } catch ( e ) {
                errorMessageLocalKey = 'XML.error.transfoXslFailedException'; // 'Error: Xsl transfo Failed : unknown error'
                errorMessageDetail = e.message;
              }
            } else {
              errorMessageLocalKey = 'XML.error.XslParseError'; // 'Error : XSL is not well formed : parse XSL error'
              errorMessageDetail = parseErrorXsl;
            }
          } catch (e) {
            errorMessageLocalKey = 'XML.error.XslParseException';  // 'Error : XSL is not well formed : unknown error'
            errorMessageDetail = e.message;
          }
        } else {
          errorMessageLocalKey = 'XML.error.XmlParseError'; // 'Error : XML is not well formed : parse XML error'
          errorMessageDetail = parseErrorXml;
        }
      } catch (e) {
        errorMessageLocalKey = 'XML.error.XmlParseException'; // 'Error : XML is not well formed : unknown error'
        errorMessageDetail = e.message;
      }
    }

    if (errorMessageLocalKey !== null ) {
      console.error('doXslTransfo error :' + errorMessageLocalKey);
      if (errorMessageDetail !== null ) {
        console.error('doXslTransfo error detail :' + errorMessageDetail);
      }
    }
    return new XslTransfoResult(result, errorMessageLocalKey, errorMessageDetail, sXml, sXsl);
  }

  public static beautyFyXslTransfoError(errorText: string , errorDetail: string , sXml: string, sXsl: string): string {
    let result = '<h2 class="errorXslTransfo">' + errorText + '</h2>';
    if (errorDetail != null ) {
      result += '<br>' + errorDetail;
    }
    if (sXml != null ) {
      result += '<br><br>XML : <br><xmp>' + sXml + '</xmp>';
    }
    if (sXsl != null ) {
      result += '<br><br>' + '<br>XSL : <br><xmp>' + sXsl + '</xmp>';
    }

    return result;
  }

  /**
   * return null if no parseError , else return parseError Text
   * @param xmlDoc
   * @private
   */
  private static getParseError( xmlDoc: XMLDocument ) {
    let parseError = null;
    if (xmlDoc !== null) {
      const tagParseErrorContainer = xmlDoc.body !== null ? xmlDoc.body.firstElementChild : xmlDoc.firstElementChild;
      if (!!tagParseErrorContainer && !!tagParseErrorContainer.tagName && tagParseErrorContainer.tagName.toLowerCase() === 'parsererror') {
        parseError = tagParseErrorContainer.innerHTML;
      }
    }

    return parseError;
  }

  public static isDeltaSecInLastMinute( deltaInSeconds: number ): boolean {
    return deltaInSeconds < 60;
  }

  public static isDeltaSecInLastHour( deltaInSeconds: number ): boolean {
    return deltaInSeconds < 3600;
  }

  public static isDeltaSecInLastDay( deltaInSeconds: number ): boolean {
    return deltaInSeconds < 86400;
  }

  /*
  public static isDeltaSecMoreThanLastDay( deltaInSeconds: number ): boolean {
    return deltaInSeconds >= 86400;
  }
  */

  public static getApplicationConfigData( propertyKey: string): string {
    let dataValue = null;

    if ( Utils.applicationConfigResponse === null ) {
      const currentLocation = document.location;
      const applicationConfigUrl = `${currentLocation.protocol}//${currentLocation.hostname}:${currentLocation.port}` + '/assets/config/application.json';
      const request = new XMLHttpRequest();
      request.open('GET', applicationConfigUrl, false);  // request application settings synchronous
      request.send(null);
      Utils.applicationConfigResponse = JSON.parse(request.responseText);
    }

    dataValue =  Utils.applicationConfigResponse[propertyKey];

    return dataValue;
  }

  public static arrayBufferToBase64( buffer ) {
    let binary = '';
    const bytes = new Uint8Array( buffer );
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  public static getAttachmentNameFromResponse( docFileResponse: HttpResponse<any> ) {
    let fileName = 'NexiaDocument';
    const contentDisposition = docFileResponse.headers.get('Content-Disposition');
    if (contentDisposition != null ) {
      try {
        fileName = contentDisposition.split(';')[1].trim().split('=')[1].replace(/"/g, '');
      } catch (e) {
        console.error('Error retrieving filename from contentDisposition :' + contentDisposition );
      }
    }
    return fileName;
  }

  public static objectTokenHeader(token: string): HttpHeaders {
    let headers = new HttpHeaders();
    if (!!token) {
      headers = headers.set('Object-Authorization', token);
    }
    return headers;
  }
}
