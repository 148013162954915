

export class EntityDetailsConstant {
  static readonly ADD_ENTITY_START = '[EntityDetails].ADD_ENTITY_START';
  static readonly ADD_ENTITY_SUCCEEDED = '[EntityDetails].ADD_ENTITY_SUCCEEDED';
  static readonly ADD_ENTITY_FAILED = '[EntityDetails].ADD_ENTITY_FAILED';

  static readonly EDITION_ENTITY_START = '[EntityDetails].EDITION_ENTITY_START';
  static readonly EDITION_ENTITY_SUCCEEDED = '[EntityDetails].EDITION_ENTITY_SUCCEEDED';
  static readonly EDITION_ENTITY_FAILED = '[EntityDetails].EDITION_ENTITY_FAILED';

  static readonly LOAD_DETAILS_START = '[EntityDetails].LOAD_DETAILS_START';
  static readonly LOAD_DETAILS_SUCCEEDED = '[EntityDetails].LOAD_DETAILS_SUCCEEDED';
  static readonly LOAD_DETAILS_FAILED = '[EntityDetails].LOAD_DETAILS_FAILED';

  static readonly LOAD_ENTITY_DETAILS_START = '[EntityDetails].LOAD_ENTITY_DETAILS_START';
  static readonly LOAD_ENTITY_DETAILS_SUCCEEDED = '[EntityDetails].LOAD_ENTITY_DETAILS_SUCCEEDED';
  static readonly LOAD_ENTITY_DETAILS_FAILED = '[EntityDetails].LOAD_ENTITY_DETAILS_FAILED';

  static readonly LOAD_ATTACHMENT_START = '[EntityDetails].LOAD_ATTACHMENT_START';
  static readonly LOAD_ATTACHMENT_SUCCEEDED = '[EntityDetails].LOAD_ATTACHMENT_SUCCEEDED';
  static readonly LOAD_ATTACHMENT_FAILED = '[EntityDetails].LOAD_ATTACHMENT_FAILED';

  static readonly LOAD_HISTORY_START = '[EntityDetails].LOAD_HISTORY_START';
  static readonly LOAD_HISTORY_SUCCEEDED = '[EntityDetails].LOAD_HISTORY_SUCCEEDED';
  static readonly LOAD_HISTORY_FAILED = '[EntityDetails].LOAD_HISTORY_FAILED';

  static readonly UPLOAD_ATTACHMENT_START = '[EntityDetails].UPLOAD_ATTACHMENT_START';
  static readonly UPLOAD_ATTACHMENT_SUCCEEDED = '[EntityDetails].UPLOAD_ATTACHMENT_SUCCEEDED';
  static readonly UPLOAD_ATTACHMENT_FAILED = '[EntityDetails].UPLOAD_ATTACHMENT_FAILED';

  static readonly DELETE_ATTACHMENT_START = '[EntityDetails].DELETE_ATTACHMENT_START';
  static readonly DELETE_ATTACHMENT_SUCCEEDED = '[EntityDetails].DELETE_ATTACHMENT_SUCCEEDED';
  static readonly DELETE_ATTACHMENT_FAILED = '[EntityDetails].DELETE_ATTACHMENT_FAILED';

  static readonly UPDATE_ATTACHMENT_START = '[EntityDetails].UPDATE_ATTACHMENT_START';
  static readonly UPDATE_ATTACHMENT_SUCCEEDED = '[EntityDetails].UPDATE_ATTACHMENT_SUCCEEDED';
  static readonly UPDATE_ATTACHMENT_FAILED = '[EntityDetails].UPDATE_ATTACHMENT_FAILED';

  static readonly LOAD_COMMENT_START = '[EntityDetails].LOAD_COMMENT_START';
  static readonly LOAD_COMMENT_SUCCEEDED = '[EntityDetails].LOAD_COMMENT_SUCCEEDED';
  static readonly LOAD_COMMENT_FAILED = '[EntityDetails].LOAD_COMMENT_FAILED';

  static readonly ADD_COMMENT_START = '[EntityDetails].ADD_COMMENT_START';
  static readonly ADD_COMMENT_SUCCEEDED = '[EntityDetails].ADD_COMMENT_SUCCEEDED';
  static readonly ADD_COMMENT_FAILED = '[EntityDetails].ADD_COMMENT_FAILED';

  static readonly UPDATE_COMMENT_START = '[EntityDetails].UPDATE_COMMENT_START';
  static readonly UPDATE_COMMENT_SUCCEEDED = '[EntityDetails].UPDATE_COMMENT_SUCCEEDED';
  static readonly UPDATE_COMMENT_FAILED = '[EntityDetails].UPDATE_COMMENT_FAILED';

  static readonly DELETE_COMMENT_START = '[EntityDetails].DELETE_COMMENT_START';
  static readonly DELETE_COMMENT_SUCCEEDED = '[EntityDetails].DELETE_COMMENT_SUCCEEDED';
  static readonly DELETE_COMMENT_FAILED = '[EntityDetails].DELETE_COMMENT_FAILED';

  static readonly ADD_REPLY_START = '[EntityDetails].ADD_REPLY_START';
  static readonly ADD_REPLY_SUCCEEDED = '[EntityDetails].ADD_REPLY_SUCCEEDED';
  static readonly ADD_REPLY_FAILED = '[EntityDetails].ADD_REPLY_FAILED';

  static readonly UPDATE_REPLY_START = '[EntityDetails].UPDATE_REPLY_START';
  static readonly UPDATE_REPLY_SUCCEEDED = '[EntityDetails].UPDATE_REPLY_SUCCEEDED';
  static readonly UPDATE_REPLY_FAILED = '[EntityDetails].UPDATE_REPLY_FAILED';

  static readonly DELETE_REPLY_START = '[EntityDetails].DELETE_REPLY_START';
  static readonly DELETE_REPLY_SUCCEEDED = '[EntityDetails].DELETE_REPLY_SUCCEEDED';
  static readonly DELETE_REPLY_FAILED = '[EntityDetails].DELETE_REPLY_FAILED';

  static readonly REMOVE_ALL_SUCCEEDED = '[EntityDetails].REMOVE_ALL_SUCCEEDED';

  static readonly UPDATE_TOKEN = '[EntityDetails].UPDATE_TOKEN';

  static readonly LOAD_CURRENT_ACTIVITY_DIFF_START = '[DocumentDetails].LOAD_CURRENT_ACTIVITY_DIFF_START';
  static readonly LOAD_CURRENT_ACTIVITY_DIFF_SUCCEEDED = '[DocumentDetails].LOAD_CURRENT_ACTIVITY_DIFF_SUCCEEDED';
  static readonly LOAD_CURRENT_ACTIVITY_DIFF_FAILED = '[DocumentDetails].LOAD_CURRENT_ACTIVITY_DIFF_FAILED';
}
