import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {domainStoreConfig} from '../../core/redux/domain/domain-store-config';
import {
  documentDetailsStoreConfig,
  entityDetailsStoreConfig,
  taskDetailsStoreConfig
} from '../../core/redux/details/details-store-config';
import {cfgDocumentInfoStoreConfig} from '../../core/redux/cfg-document-info/cfg-document-info-store-config';
import {
  documentFilterStoreConfig,
  documentSearchItemStoreConfig,
  documentSearchResultStoreConfig
} from '../../core/redux/document-search/document-search-store-config';
import {
  entityFilterStoreConfig, entitySearchItemStoreConfig,
  entitySearchResultStoreConfig,
  entityViewStoreConfig
} from '../../core/redux/entity-search/entity-search-store-config';
import {gedCfgFieldStoreConfig, wkfCfgFieldStoreConfig} from '../../core/redux/field/ged-cfg-field-store-config';
import {formStoreConfig} from '../../core/redux/form/form-store-config';
import {
  cacheDraftStoreConfig,
  docTypesStoreConfig,
  draftDataStoreConfig,
  draftFilterStoreConfig,
  listIndexationStoreConfig
} from '../../core/redux/indexation/indexation-store-config';
import {maintenanceInfoStoreConfig} from '../../core/redux/maintenance-info/maintenance-info-store-config';
import {currentContextStoreConfig} from '../../core/redux/current-context/current-context-store-config';
import {criticalDataStoreConfig} from '../../core/redux/critical-data/critical-data-store-config';
import {menuStoreConfig} from '../../core/redux/menu/menu-store-config';
import {nexiaBasketStoreConfig} from '../../core/redux/nexia-basket/nexia-basket-store-config';
import {nexiaObjectLinksStoreConfig} from '../../core/redux/nexia-object-links/nexia-object-links-store-config';
import {routerPathStoreConfig} from '../../core/redux/router-path/router-path-store-config';
import {
  searchResultPaginationStoreConfig
} from '../../core/redux/search-result-pagination/search-result-pagination-store-config';
import {tableFilterStoreConfig} from '../../core/redux/table-filter/table-filter-store-config';
import {authenticationStoreConfig} from '../../modules/authentication/authentication-store-config';
import {currentCommentStoreConfig} from '../../core/redux/current-comment/current-comment-store-config';
import {viewStoreConfig} from '../../core/redux/view/view-store-config';
import {versionListStoreConfig} from '../../core/redux/version-list/version-list-store-config';
import {userStoreConfig} from '../../core/redux/user/user-store-config';
import {teamPageStoreConfig} from '../../core/redux/teams/teams-page/team-page-store-config';
import {
  currentTeamUsersPageStoreConfig
} from '../../core/redux/teams/current-team-users-page/current-team-users-page-store-config';
import {currentTeamStoreConfig} from '../../core/redux/teams/current-team/current-team-store-config';
import {cfgTeamStoreConfig} from '../../core/redux/teams/cfg-teams/cfg-team-store-config';
import {
  taskSearchResultStoreConfig
} from '../../core/redux/task-search/task-search-result/task-search-result-store-config';
import {taskSearchItemStoreConfig} from '../../core/redux/task-search/task-search-item/task-search-item-store-config';
import {taskFilterStoreConfig} from '../../core/redux/task-search/task-filter/task-filter-store-config';
import {taskFormStoreConfig} from '../../core/redux/task-form/task-form-store-config';
import { StoreModule } from '@ngrx/store';

const featureStoresConfigs = [
  domainStoreConfig,
  authenticationStoreConfig,
  criticalDataStoreConfig,
  currentCommentStoreConfig,
  currentContextStoreConfig,
  documentDetailsStoreConfig,
  entityDetailsStoreConfig,
  taskDetailsStoreConfig,
  cfgDocumentInfoStoreConfig,
  documentFilterStoreConfig,
  documentSearchItemStoreConfig,
  documentSearchResultStoreConfig,
  entitySearchResultStoreConfig,
  entityFilterStoreConfig,
  entitySearchItemStoreConfig,
  entityViewStoreConfig,
  maintenanceInfoStoreConfig,
  gedCfgFieldStoreConfig,
  wkfCfgFieldStoreConfig,
  viewStoreConfig,
  formStoreConfig,
  cacheDraftStoreConfig,
  docTypesStoreConfig,
  draftDataStoreConfig,
  draftFilterStoreConfig,
  listIndexationStoreConfig,
  versionListStoreConfig,
  userStoreConfig,
  teamPageStoreConfig,
  currentTeamUsersPageStoreConfig,
  currentTeamStoreConfig,
  cfgTeamStoreConfig,
  taskSearchResultStoreConfig,
  menuStoreConfig,
  taskSearchItemStoreConfig,
  nexiaBasketStoreConfig,
  nexiaObjectLinksStoreConfig,
  routerPathStoreConfig,
  searchResultPaginationStoreConfig,
  routerPathStoreConfig,
  taskFilterStoreConfig,
  tableFilterStoreConfig,
  taskFormStoreConfig,
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(),
    ...featureStoresConfigs
  ]
})
export class NexiaStoreModule {
}
