import {HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import {AuthenticationResult} from '@azure/msal-common';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Observable, of, Subject, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {AuthenticationService} from '../../../../modules/authentication/authentication.service';
import {ApplicationConfigVarNames} from '../../../../modules/configuration/application-config-var-names';
import {ConfigurationService} from '../../../../modules/configuration/configuration.service';
import {HttpErrorInterceptor} from '../../../../modules/http-error-interceptor/http-error-interceptor';
import {BlobFile} from '../../../models/blob-file';
import {ShareLinkData} from '../../../models/share-link-data';
import {AzureService, UploadSessionObject} from '../azure.service';
import {DateUtils} from '../../../utils/dateUtils';
import {CheckinStructure} from '../../../redux/details/service/document/document-details.service';

@Injectable({
  providedIn: 'root'
})
export class ShareFileService extends AzureService {

  public static readonly FORCE_DELETE_DRIVE_ITEM = 'bypass-shared-lock';
  private static readonly DRIVE_URL = ShareFileService.AZURE_URL + 'drive/';
  private static readonly ROOT = 'root:/';
  private static readonly CONTENT = '/content';
  private static readonly ROOT_CHILDREN = 'root/children';
  private static readonly ITEMS = 'items/';
  private static readonly CREATE_LINK = '/createLink';
  private static readonly OPERATOR = ':';
  private shareDirName = 'Nexia';

  constructor(
    @Inject(MSAL_GUARD_CONFIG) msalGuardConfig: MsalGuardConfiguration,
    broadcastService: MsalBroadcastService,
    msAuthService: MsalService,
    httpClient: HttpClient,
    translateService: TranslateService,
    authenticationService: AuthenticationService,
    configAction: ConfigurationService
  ) {
    super(msalGuardConfig, broadcastService, msAuthService, httpClient, translateService, authenticationService, configAction);
    this.configService.applicationConfigReady$.subscribe(() => this.init())
  }

  private static createNexiaFolder(name: string): any {
    const folder: any = {};
    folder['name'] = name;
    folder['folder'] = {};
    folder['@microsoft.graph.conflictBehavior'] = 'fail';
    return folder;
  }

  private static createNexiaTempFolder(namePrefix: string): any {
    return this.createNexiaFolder( namePrefix+'-'+DateUtils.dateToHorodatage() );
  }

  init() {
    const configShareDirName = this.configService.getConfigVariable(ApplicationConfigVarNames.AZURE_MSAL_SHARE_DIR_NAME);
    this.shareDirName = !!configShareDirName && configShareDirName.length ? configShareDirName : this.shareDirName;
  }

  public checkOfficeLogin(): Observable<AuthenticationResult> {
    return this.checkLogin();
  }

  public checkLoginAndUpsertShareFolder(): Observable<boolean> {
    const isDone: Subject<boolean> = new Subject<boolean>();
    this.checkLogin().pipe(
      switchMap((response: AuthenticationResult) => {
        if (response) {
          return this.createDriveNexiaFolderIfDoesNotExist();
        } else {
          // TODO treat login out
          throw new Error('Failed to login');
        }
      })
    ).subscribe((response: any) => {
      console.log(response);
      isDone.next(true);
      isDone.complete();
    }, error => {
      console.error('error: ', error);
      if (error.status === 404) {
        // TODO folder allready created
      }
      if (error.message === 'user_cancelled: User cancelled the flow.') {
        // TODO user canceled the azure connection
      }
      isDone.next(false);
      isDone.complete();
    });
    return isDone.asObservable();
  }

  public addFileToDriveAndCreateShareLink(file: BlobFile): Observable<any> {
    let fileId = '';
    const headers = new HttpHeaders().set(HttpErrorInterceptor.BYPASS_HEADER, '');
    let request: Observable<HttpResponse<any>>;
    if (file.fileSize >= ShareFileService.BIG_FILE_SIZE) {
      const url = ShareFileService.DRIVE_URL + ShareFileService.ROOT + this.shareDirName + '/' + file.filename + ShareFileService.OPERATOR + ShareFileService.CREATE_UPLOAD_SESSION;
      const bodyObject: any = {
        item: ShareFileService.createMsDriveBodyItem()
      };
      const uploadSessionObject: UploadSessionObject = {
        file,
        headers,
        url,
        bodyItem: bodyObject
      };
      request = this.uploadFileBySession(uploadSessionObject);
    } else {
      request = this.uploadFileToDrive(file, headers);
    }
    return request.pipe(
      switchMap((response: HttpResponse<any>) => {
        fileId = response.body.id;
        return this.createShareLink(fileId);
      }),
      catchError((error) => {
        // TODO treat error for upload file on drive
        console.error(error);
        return throwError(error);
      }),
      map((shareLinkData: any) => {
        return {
          fileId,
          shareLink: shareLinkData.link.webUrl
        } as ShareLinkData;
      })
    );
  }

  public deleteDriveItem(driveFileId: string): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders()
      .set('prefer', ShareFileService.FORCE_DELETE_DRIVE_ITEM)
      .set(HttpErrorInterceptor.BYPASS_HEADER, '');
    return this.httpClient.delete(ShareFileService.DRIVE_URL + ShareFileService.ITEMS + driveFileId, {
      headers,
      observe: 'response'
    });
  }

  public checkinFile(response: CheckinStructure): Observable<CheckinStructure> {
    const driveFileId = response.move.tempId;
    const originalFileName = response.move.originalName;

    return forkJoin([this.getDriveFile(driveFileId), this.getDriveItem(driveFileId)])
      .pipe(
        switchMap(([blob, driveItem]: [HttpResponse<Blob>, HttpResponse<any>]) => {
          console.log(blob);
          console.log(driveItem);
          const file = new File([blob.body], originalFileName, {type: blob.body.type});
          return of({
            ...response,
            state: 'checkin',
            file: file
          } as CheckinStructure);
        }),
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          return of({
            ...response,
            state: 'error'
          } as CheckinStructure);
        })
      );
  }


  private getDriveItemUrl(driveFileId: string): string {
    return ShareFileService.DRIVE_URL + ShareFileService.ITEMS + driveFileId;
  }

  public renameDriveItem(checkinStructure: CheckinStructure): Observable<CheckinStructure> {
    const driveFileId = checkinStructure.metadata.archive_lock.lock_information.office_id
    return this.getDriveItem(driveFileId).pipe(
      switchMap( (response: HttpResponse<any>) => {
        if ( !response ) { return throwError('Failed to retrieve original file'); }
        // const jsResponse = JSON.parse( response.body );
        const jsResponse = response.body;
        const originalName: string = jsResponse['name'];
        return this.httpClient.patch( this.getDriveItemUrl(driveFileId), {
          name: 'tmp_nexia_'+DateUtils.dateToHorodatage() + '__' +originalName
        }, {
          observe: 'response',
          headers: new HttpHeaders().set(HttpErrorInterceptor.BYPASS_HEADER, '')
        } ).pipe(
          switchMap( (response) => {
            if (response) {
              const tempName: string = response.body['name'];
              const tempId: string = response.body['id'];
              return of({
                ...checkinStructure,
                state: "moved",
                move: {
                  originalName: originalName,
                  tempName: tempName,
                  tempId: tempId
                }
              } as CheckinStructure)
            } else {
              // throw new Error('Failed to rename');
              console.error('Failed to rename');
              return of({...checkinStructure, state: 'locked'} as CheckinStructure);
            }
          } ),
          catchError((error: any) => {
            console.error(error);
            // return throwError(error);
            return of({...checkinStructure, state: 'locked'} as CheckinStructure);
          })
        );
      }
    ) );
  }



  private getDriveFile(driveFileId: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(ShareFileService.DRIVE_URL + ShareFileService.ITEMS + driveFileId + ShareFileService.CONTENT, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  private getDriveItem(driveFileId: string): Observable<HttpResponse<any>> {
    return this.httpClient.get(ShareFileService.DRIVE_URL + ShareFileService.ITEMS + driveFileId, {
      observe: 'response'
    });
  }


  private createDriveNexiaFolderIfDoesNotExist(): Observable<any> {
    return this.createDriveFolderIfDoesNotExist( ShareFileService.createNexiaFolder(this.shareDirName) )
  }

  private createDriveNexiaTempFolderIfDoesNotExist(): Observable<any> {
    return this.createDriveFolderIfDoesNotExist( ShareFileService.createNexiaTempFolder(this.shareDirName) )
  }

  private createDriveFolderIfDoesNotExist(folder: any): Observable<any> {
    return this.getDriveFolder().pipe(
      catchError((error: HttpErrorResponse) => {
        const parsedError: any = JSON.parse(error.error);
        if (error.status === 404 && parsedError.error.code === 'itemNotFound') {
          return of(null);
        }
      }),
      switchMap((driveFolder) => {
        if (driveFolder) {
          return of(JSON.parse(driveFolder.body));
        } else {
          return this.httpClient.post(ShareFileService.DRIVE_URL + ShareFileService.ROOT_CHILDREN, folder, {
            responseType: 'text',
            observe: 'response'
          }).pipe(
            map((response: HttpResponse<any>) => JSON.parse(response.body))
          );
        }
      })
    );
  }

  private createShareLink(fileId: string): Observable<any> {
    const shareLinkData = {
      type: 'edit',
      scope: 'organization'
    };
    return this.httpClient.post(ShareFileService.DRIVE_URL + ShareFileService.ITEMS + fileId + ShareFileService.CREATE_LINK, shareLinkData);
  }

  private uploadFileToDrive(file: BlobFile, headers: HttpHeaders): Observable<HttpResponse<any>> {
    return this.httpClient.put(ShareFileService.DRIVE_URL + ShareFileService.ROOT + this.shareDirName + '/' + file.filename + ShareFileService.OPERATOR + ShareFileService.CONTENT,
      file.blob, {headers: headers, observe: 'response'}
    );
  }

  private getDriveFolder(): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders().set(HttpErrorInterceptor.BYPASS_HEADER, '');
    return this.httpClient.get(ShareFileService.DRIVE_URL + ShareFileService.ROOT + this.shareDirName, {
      headers: headers,
      responseType: 'text',
      observe: 'response'
    });
  }
}
