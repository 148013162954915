import {ApplicationConfigVarNames} from '../../modules/configuration/application-config-var-names';
import {ConfigurationService} from '../../modules/configuration/configuration.service';

export class Url {
  // services
  static readonly ADMIN_CLI = 'admin';

  // public urls
  static readonly PUBLIC_API = 'public/';
  static readonly SUPPORT_API = 'support/';

  static readonly LOGIN = 'login/';


  // protected urls
  static readonly PROTECTED_API = 'rest/';
  static readonly PROCESS_INSTANCE = 'process-instance/';
  static readonly ENGINE_REST = 'engine-rest/';
  static readonly WKF_REST = 'wkf-rest/';

  static readonly DOCUMENTS = 'documents/';
  static readonly DOCUMENT = 'document/';
  static readonly TASKS = 'tasks/';
  static readonly TASK = 'task/';
  static readonly DETAILS = 'details/';
  static readonly DELETE = 'delete/';
  static readonly SEARCH = 'search/';
  static readonly AGGS = 'aggs/';
  static readonly LINKS = 'entities/';
  static readonly PARENT_ENTITIES = 'parentEntities/';
  static readonly COMMENTS = 'comments/';
  static readonly COMMENTS_FILE = 'commentedPdf/';
  static readonly REPLY = 'reply/';
  static readonly ATTACHMENT = 'attachments/';
  static readonly HISTORY = 'activities/';
  static readonly FILE = 'file/';
  static readonly ZIP = 'zip/';
  static readonly TO_EXCEL = 'document/excel/';
  static readonly FORMATS = 'formats/';
  static readonly THUMB = 'thumb/';
  static readonly DOCUMENT_PDF = 'view/';
  static readonly INDEXATION = 'indexations/';
  static readonly MULTIPLE = 'multiple/';
  static readonly DRAFT = 'drafts/';
  static readonly INDEXATION_PDF = 'view/';
  static readonly INDEXATION_FILE = 'file/';
  static readonly INDEXATION_DOC_TYPE = 'docTypes/';
  static readonly UTILS = 'utils/';
  static readonly TO_PDF = 'toPdf/';
  static readonly VIEW = 'views/';
  static readonly RECAP = 'recap/';
  static readonly ENTITY_RECAP = 'recap/entities/';
  static readonly DOMAINS = 'domains/';
  static readonly INFOS = 'documents/info/';
  static readonly FIELDS = 'fields/';
  static readonly LIST = 'list/';
  static readonly LIST_ITEM = 'listItem/';
  static readonly LOTS = 'indexEntities/';
  static readonly DOC_TYPES = 'documentTypes/';
  static readonly AGGREGATES = 'aggregates/';
  static readonly SEARCH_LIST = 'searchList';
  static readonly USERS = 'users/';
  static readonly NEXIA_USERS = 'nexiaUsers/';
  static readonly THESAURUS = 'thesaurus/';
  static readonly ITEMS = 'items/';
  static readonly CHILDREN = 'children/';
  static readonly TEAMS = 'teams/';
  static readonly MY_TEAMS = 'myteams/';
  static readonly DUPLICATE = 'duplicate/';
  static readonly EXISTS = 'exists/';
  static readonly PARAMS = 'params/';
  static readonly MENU = 'menu';
  static readonly HISTORY_EVT_REV = 'history/';
  static readonly REVISIONS = 'revisions/';

  static readonly AUTOCOMPLETE_KEYWORD = 'autocomplete/keyword/';
  static readonly AUTOCOMPLETE_VALUE = 'autocomplete/value/';
  static readonly AUTOCOMPLETE_KEY_VALUE = 'autocomplete/keywordValue/';
  static readonly AUTOCOMPLETE_AGGREGATE = 'autocomplete/aggregate/';
  static readonly FOLDERS = 'folders/';
  static readonly CUSTOM_LINKS = 'custlinks/';
  static readonly ACTIONS = 'actions/';
  static readonly RESTORE_REVISION = 'restoreRevision/';

  static readonly DEPLOYED_FORM = 'deployed-form/';
  static readonly FORM_VARIABLES = 'form-variables/';
  static readonly VARIABLES = 'variables/';
  static readonly COMPLETE = 'complete/';
  static readonly CLAIM = 'claim/';

  static readonly LOCK = 'lock/';
  static readonly METADATA = 'metadata';


  static getWkfProtectedApiBaseUrl(configService: ConfigurationService): string {
    return `${this.getWkfApiBaseUrl(configService)}${Url.WKF_REST}`;
  }

  static getCamundaProtectedApiBaseUrl(configService: ConfigurationService): string {
    return `${this.getWkfApiBaseUrl(configService)}${Url.ENGINE_REST}`;
  }

  static getProtectedApiBaseUrl(configService: ConfigurationService): string {
    return `${this.getApiBaseUrl(configService)}${Url.PROTECTED_API}`;
  }

  static getSupportApiBaseUrl(configService: ConfigurationService): string {
    return `${this.getApiBaseUrl(configService)}${Url.SUPPORT_API}`;
  }

  static getAdminFrontBaseUrl(configService: ConfigurationService): string {
    return `${configService.getConfigVariable(ApplicationConfigVarNames.API_BACK_URL)}/${Url.ADMIN_CLI}`;
  }


  static getApiBackUrl(configService: ConfigurationService): string {
    return `${configService.getConfigVariable(ApplicationConfigVarNames.API_BACK_URL)}`;
  }

  private static getApiBaseUrl(configService: ConfigurationService): string {
    let backName: string = configService.getConfigVariable(ApplicationConfigVarNames.API_BACK_NAME) || '';
    if (backName && !backName.endsWith('/')) {
      backName += '/';
    }
    return `${configService.getConfigVariable(ApplicationConfigVarNames.API_BACK_URL)}/${backName}`;
  }

  private static getWkfApiBaseUrl(configService: ConfigurationService): string {
    let backName: string = configService.getConfigVariable(ApplicationConfigVarNames.WKF_API_BACK_NAME) || '';
    if (backName && !backName.endsWith('/')) {
      backName += '/';
    }
    return `${configService.getConfigVariable(ApplicationConfigVarNames.WKF_API_BACK_URL)}/${backName}`;
  }
}
