import {createFeatureSelector, createSelector} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {DocumentDetailsState} from './reducer/document/document-details.reducer';
import {EntityDetailsState} from './reducer/entity/entity-details.reducer';
import {TaskDetailsState} from './reducer/task/task-details.reducer';


export const selectDetailsDocumentFeature = createFeatureSelector<DocumentDetailsState>(StoreKeys.DOCUMENT_DETAILS);

export const selectDocumentDetailsDatas = createSelector(selectDetailsDocumentFeature, state => state.datas);

export const selectDocumentDatasDetails = createSelector(selectDocumentDetailsDatas, datas => datas?.details);

export const selectDocumentDetailsDatasActivities = createSelector(selectDocumentDetailsDatas, state => state.activities);

export const selectDocumentDetailsDatasDetailsMetadata = createSelector(selectDocumentDatasDetails, state => state.metadata);

export const selectDocumentDetailsDatasCurrentActivityDiff = createSelector(selectDocumentDetailsDatas, state => state.currentActivityDiff);

export const selectDocumentDetailsDatasComments = createSelector(selectDocumentDetailsDatas, state => state?.comments);

export const selectDocumentDetailsDatasAttachment = createSelector(selectDocumentDetailsDatas, state => state.attachments);

export const selectDocumentDetailsLoading = createSelector(selectDetailsDocumentFeature, state => state.loading);

export const selectDocumentDetailsError = createSelector(selectDetailsDocumentFeature, state => state.error);

export const selectDetailsDocumentInfosBlock = createSelector(selectDetailsDocumentFeature, state => state.displayInfoBlock);

export const selectDetailsEntityFeature = createFeatureSelector<EntityDetailsState>(StoreKeys.ENTITY_DETAILS);

export const selectDetailsEntityDatas = createSelector(selectDetailsEntityFeature, state => state.datas);

export const selectDetailsEntityDatasCurrentActivityDiff = createSelector(selectDetailsEntityDatas, state => state?.currentActivityDiff);

export const selectDetailsEntityDatasActivities = createSelector(selectDetailsEntityDatas, state => state?.activities);

export const selectDetailsEntityDatasDetails = createSelector(selectDetailsEntityDatas, state => state?.details);

export const selectDetailsEntityDatasComments = createSelector(selectDetailsEntityDatas, state => state.comments);

export const selectDetailsEntityLoading = createSelector(selectDetailsEntityFeature, state => state.loading);

export const selectDetailsEntityError = createSelector(selectDetailsEntityFeature, state => state.error);

export const selectDetailsEntityDatasAttachment = createSelector(selectDetailsEntityDatas, state => state.attachments);

export const selectDetailsTaskFeature = createFeatureSelector<TaskDetailsState>(StoreKeys.TASK_DETAILS);

export const selectDetailsTaskDisplayInfoBlock = createSelector(selectDetailsTaskFeature, state => state.displayInfoBlock)

export const selectDetailsTaskDatas = createSelector(selectDetailsTaskFeature, state => state.datas);

export const selectDetailsTasksDatasComments = createSelector(selectDetailsTaskDatas, state => state.comments);

export const selectDetailsTaskDatasDetails = createSelector(selectDetailsTaskDatas, datas => datas?.details);

export const selectDetailsTaskDatasCurrentActivityDiff = createSelector(selectDetailsTaskDatas, state => state.currentActivityDiff);

export const selectDetailsTaskDatasActivities = createSelector(selectDetailsTaskDatas, state => state.activities);

export const selectDetailsTaskDatasLoading = createSelector(selectDetailsTaskFeature, state => state.loading);

export const selectDetailsTaskDatasError = createSelector(selectDetailsTaskFeature, state => state.error);

export const selectDetailsTaskDatasAttachments = createSelector(selectDetailsTaskDatas, state => state.attachments);

